import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Svg,
  Path,
} from '@react-pdf/renderer';

import {
  IAccountStatementBE,
  IAccountStatementOpenPositions,
} from '@trader/api';
import { appConfigUtils, formatDate } from '@trader/utils';
import { useI18next } from '@trader/services';

import {
  formatDecimal,
  formatQuantityDisplay,
  getAccountStatementActionDescription,
  getDealsTableData,
  getTranslatedDirection,
  TDealsTableDataItem,
} from '../../utils';
import { IAccountStatementHtmlReport } from '../htmlReport';
import { styles } from './styled';

export interface IAccountStatementPdfReport
  extends IAccountStatementHtmlReport {
  balanceChart: string | null;
  logo;
}

export const PdfReport: React.FC<IAccountStatementPdfReport> = ({
  from,
  to,
  platformLogin,
  currency,
  product,
  accountType,
  accountStatement,
  balanceChart,
  logo,
}) => {
  return (
    <Document style={styles.page}>
      <Page size='A4' orientation='landscape' style={styles.page}>
        <View style={styles.section}>
          <View style={styles.column}>
            <Header logo={logo} />
            <Title from={from} to={to} />
            <AccountInfo
              lastName={accountStatement.customerInfo.lastName}
              firstName={accountStatement.customerInfo.firstName}
              accountType={accountType}
              product={product}
              platformLogin={platformLogin}
              brand={appConfigUtils.getCurrentBrand()}
              currency={currency}
            />
            <Summary accountStatement={accountStatement} />
            <CostOverview accountStatement={accountStatement} />
            <Chart balanceChart={balanceChart} />
            <Positions accountStatement={accountStatement} />
            <Deals accountStatement={accountStatement} />
            <Results accountStatement={accountStatement} currency={currency} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const TableData = ({ label, value, viewStyles, labelStyles, valueStyles }) => (
  <View style={viewStyles}>
    <Text style={labelStyles}>{label}</Text>
    <Text style={valueStyles}>{value}</Text>
  </View>
);

const Header = ({ logo }) => {
  return (
    <>
      <View style={styles.row}>
        <View style={styles.col3}>
          <View style={styles.column_MT_10}>{logo}</View>
        </View>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Title = ({ from, to }: { from: string; to: string }) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h1}>
          {translate('COMMON.LABELS.TRADE_HISTORY_REPORT')}
        </Text>
        <Text style={styles.b}>
          {formatDate(new Date(from), 'dd.mm.yyyy')} -{' '}
          {formatDate(new Date(to), 'dd.mm.yyyy')}
        </Text>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const AccountInfo = ({
  firstName,
  lastName,
  platformLogin,
  currency,
  product,
  accountType,
  brand,
}: {
  firstName: string;
  lastName: string;
  platformLogin: string;
  currency: string;
  product: string;
  accountType: string;
  brand: string;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <View style={styles.row}>
          <View style={styles.col9}>
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.NAME')}
              value={`${firstName} ${lastName}`}
            />
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.ACCOUNT')}
              value={`${platformLogin} (${currency}, ${product}, ${accountType})`}
            />
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.COMPANY')}
              value={brand}
            />
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.DATE')}
              value={formatDate(new Date(), 'dd.mm.yyyy hh:mm:ss')}
            />
          </View>
        </View>
      </View>
      <View style={{ ...styles.divider, margin: '5px 0 10px 0' }} />
    </>
  );
};

const Summary = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>{translate('COMMON.LABELS.SUMMARY')}</Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.BALANCE')}
            value={formatDecimal(accountStatement.summary.balance)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.USED_MARGIN')}
            value={formatDecimal(accountStatement.summary.usedMargin)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.DEPOSIT')}
            value={formatDecimal(accountStatement.summary.deposit)}
          />
        </View>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.EQUITY')}
            value={formatDecimal(accountStatement.summary.equity)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.USABLE_MARGIN')}
            value={formatDecimal(accountStatement.summary.marginFree?.value)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.WITHDRAWAL')}
            value={formatDecimal(accountStatement.summary.withdrawal)}
          />
        </View>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.P_L')}
            value={formatDecimal(accountStatement.summary.grossPl)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={`${translate('COMMON.LABELS.USABLE_MARGIN')}, %`}
            value={`${formatDecimal(
              accountStatement.summary.marginFree?.percent
            )}%`}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.ADJUSTMENT')}
            value={formatDecimal(accountStatement.summary.adjustment)}
          />
        </View>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.REALIZED_TOTAL_PL')}
            value={formatDecimal(accountStatement.summary.realizedPl)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={`${translate('COMMON.LABELS.EQUITY_MC_LEVEL')}, %`}
            value={`${formatDecimal(accountStatement.summary.mcLevel)}%`}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.NET')}
            value={formatDecimal(accountStatement.summary.net)}
          />
        </View>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const CostOverview = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>
          {translate('COMMON.LABELS.COSTS_OVERVIEW')}
        </Text>
      </View>
      <View style={styles.column_MT_10}>
        <Text style={styles.h3}>
          {translate('COMMON.LABELS.TRADING_COSTS')}
        </Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.col11}>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text></Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.ACTIVE_TRADING_COSTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.CLOSED_TRADING_COSTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.TOTAL_TRADING_COSTS')}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.OPEN_COMMISSIONS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading?.openCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.openCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.openCommissions
                )}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.CLOSE_COMMISSIONS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading
                    ?.closeCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.closeCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.closeCommissions
                )}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col3}>
              <Text style={styles.b}>{translate('COMMON.LABELS.SPREAD')}</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading?.spread
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.spread
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.spread
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.column_MT_10}>
        <Text style={styles.h3}>
          {translate('COMMON.LABELS.OTHER_ADJUSTMENTS')}
        </Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.col11}>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text></Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.ACTIVE_OTHER_ADJUSTMENTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.CLOSED_OTHER_ADJUSTMENTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.TOTAL_OTHER_ADJUSTMENTS')}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.OVERNIGHT_SWAP')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.swap
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.swap
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.swap
                )}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.DIVIDENDS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.dividends
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.dividends
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.dividends
                )}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col3}>
              <Text style={styles.b}>{translate('COMMON.LABELS.WHT')}</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.wht
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.wht
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.wht
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Chart = ({ balanceChart }: { balanceChart: string | null }) => {
  const { translate } = useI18next();

  if (!balanceChart) {
    return null;
  }

  return (
    <>
      <View style={{ ...styles.divider, margin: '48px 0 15px 0' }} />
      <View style={styles.column}>
        <View style={styles.column}>
          <Text style={{ ...styles.h2, marginBottom: '10px' }}>
            {translate('COMMON.LABELS.BALANCE_CHART')}
          </Text>
        </View>
        <Image src={balanceChart} style={{ width: '100%', height: '120px' }} />
      </View>
      <View style={{ ...styles.divider, margin: '-5px 0 15px 0' }} />
    </>
  );
};

const Positions = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  const tableData: {
    label: string;
    getValue: (position: IAccountStatementOpenPositions) => number | string;
  }[] = [
    {
      label: translate('COMMON.LABELS.POSITION_ID'),
      getValue: position => position.positionId || '-',
    },
    {
      label: translate('COMMON.LABELS.ENTRY_TIME'),
      getValue: position =>
        typeof position.entryTime === 'string'
          ? formatDate(
              new Date(position.entryTime as unknown as string),
              'dd.mm.yyyy hh:mm'
            )
          : '-',
    },
    {
      label: translate('COMMON.LABELS.SYMBOL'),
      getValue: position => position.symbol || '-',
    },
    {
      label: translate('COMMON.LABELS.DIRECTION'),
      getValue: position =>
        getTranslatedDirection(position.direction, translate),
    },
    {
      label: translate('COMMON.LABELS.QUANTITY'),
      getValue: position =>
        formatQuantityDisplay(position.quantity, position.remainingQuantity),
    },
    {
      label: translate('COMMON.LABELS.ENTRY_PRICE'),
      getValue: position => position.entryPrice,
    },
    {
      label: translate('COMMON.LABELS.STOP_LOSS'),
      getValue: position => position.stopLoss,
    },
    {
      label: translate('COMMON.LABELS.TAKE_PROFIT'),
      getValue: position => position.takeProfit,
    },
    {
      label: translate('COMMON.LABELS.SWAP'),
      getValue: position => position.swap,
    },
    {
      label: translate('COMMON.LABELS.OPEN_COMMISSION'),
      getValue: position => position.openCommission,
    },
    {
      label: translate('COMMON.LABELS.CLOSE_COMMISSION'),
      getValue: position => position.closeCommission,
    },
    {
      label: translate('COMMON.LABELS.PRICE'),
      getValue: position => position.price,
    },
    {
      label: translate('COMMON.LABELS.FEE'),
      getValue: balance => balance.fee,
    },
    {
      label: translate('COMMON.LABELS.P_L'),
      getValue: balance => formatDecimal(balance.pl),
    },
    {
      label: translate('COMMON.LABELS.COMMENT'),
      getValue: balance => balance.comment || '-',
    },
  ];

  const getColWidth = (index: number) => {
    // eslint-disable-next-line no-magic-numbers
    const colWidth = '10%';
    const dateWidth = '80px';

    return index === 1 ? dateWidth : colWidth;
  };

  if (!accountStatement.openPositions.length) {
    return (
      <>
        <Text style={{ ...styles.h2, marginBottom: '10px' }}>
          {translate('COMMON.LABELS.POSITIONS')}
        </Text>
        <Text style={styles.span}>
          {translate('COMMON.LABELS.NO_TRANSACTIONS')}
        </Text>
      </>
    );
  }

  return (
    <>
      <Text style={{ ...styles.h2, marginBottom: '10px' }}>
        {translate('COMMON.LABELS.POSITIONS')}
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {tableData.map((data, index) => (
            <View key={data.label} style={{ width: getColWidth(index) }}>
              <Text style={styles.tableCellHeader}>{data.label}</Text>
            </View>
          ))}
        </View>
        {accountStatement.openPositions.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <View key={index} style={styles.tableRow}>
            {tableData.map(data => (
              <View key={data.label} style={{ width: getColWidth(index) }}>
                <Text style={styles.tableCell}>{data.getValue(item)}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Deals = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();
  const tableData = getDealsTableData(accountStatement);

  const mainRowTableColumns: {
    label: string;
    getValue: (item: TDealsTableDataItem) => number | string;
  }[] = [
    {
      label: translate('COMMON.LABELS.POSITION_ID'),
      getValue: item => item.positionId || '-',
    },
    {
      label: translate('COMMON.LABELS.ORDER_ID'),
      getValue: item => item.positionId || '-',
    },
    {
      label: translate('COMMON.LABELS.ENTRY_TIME'),
      getValue: item =>
        typeof item.entryTime === 'string'
          ? formatDate(
              new Date(item.entryTime as unknown as string),
              'dd.mm.yyyy hh:mm'
            )
          : '-',
    },
    {
      label: translate('COMMON.LABELS.EXIT_TRANSFER_TIME'),
      getValue: item =>
        typeof item.exitTime === 'string'
          ? formatDate(
              new Date(item.exitTime as unknown as string),
              'dd.mm.yyyy hh:mm'
            )
          : '-',
    },
    {
      label: translate('COMMON.LABELS.SYMBOL'),
      getValue: item => item.symbol || '-',
    },
    {
      label: translate('COMMON.LABELS.DIRECTION'),
      getValue: item => getAccountStatementActionDescription(item.action),
    },
    {
      label: translate('COMMON.LABELS.QUANTITY'),
      getValue: item =>
        formatQuantityDisplay(item.quantity, item.remainingQuantity, {
          dealsFormatting: true,
        }),
    },
    {
      label: translate('COMMON.LABELS.ENTRY_PRICE'),
      getValue: item => item.entryPrice,
    },
    {
      label: translate('COMMON.LABELS.EXIT_PRICE'),
      getValue: item => item.exitPrice,
    },
    {
      label: translate('COMMON.LABELS.FEE'),
      getValue: item => item.fee,
    },
    {
      label: translate('COMMON.LABELS.COMMISSION'),
      getValue: item => item.commission,
    },
    {
      label: translate('COMMON.LABELS.SWAP'),
      getValue: item => item.swap,
    },
    {
      label: translate('COMMON.LABELS.PROFIT_LOSS_BALANCE'),
      getValue: item => formatDecimal(item.pl),
    },
    {
      label: translate('COMMON.LABELS.COMMENT'),
      getValue: item => item.comment || '-',
    },
  ];

  const subRowTableColumns: {
    label: string;
    getValue: (item: TDealsTableDataItem) => number | string;
  }[] = [
    {
      label: translate('COMMON.LABELS.POSITION_ID'),
      getValue: item => item.positionId || '-',
    },
    {
      label: translate('COMMON.LABELS.ORDER_ID'),
      getValue: item => item.deal?.dealId || '-',
    },
    {
      label: translate('COMMON.LABELS.ENTRY_TIME'),
      getValue: () => '-',
    },
    {
      label: translate('COMMON.LABELS.EXIT_TRANSFER_TIME'),
      getValue: item =>
        typeof item.deal?.time === 'string'
          ? formatDate(
              new Date(item.deal.time as unknown as string),
              'dd.mm.yyyy hh:mm'
            )
          : '-',
    },
    {
      label: translate('COMMON.LABELS.SYMBOL'),
      getValue: () => '-',
    },
    {
      label: translate('COMMON.LABELS.DIRECTION'),
      getValue: item =>
        item.deal?.action
          ? getAccountStatementActionDescription(item.deal.action)
          : '-',
    },
    {
      label: translate('COMMON.LABELS.QUANTITY'),
      getValue: item => item.deal?.quantity || 0,
    },
    {
      label: translate('COMMON.LABELS.ENTRY_PRICE'),
      getValue: item => item.deal?.entry || 0,
    },
    {
      label: translate('COMMON.LABELS.EXIT_PRICE'),
      getValue: () => '-',
    },
    {
      label: translate('COMMON.LABELS.FEE'),
      getValue: () => '-',
    },
    {
      label: translate('COMMON.LABELS.COMMISSION'),
      getValue: () => '-',
    },
    {
      label: translate('COMMON.LABELS.SWAP'),
      getValue: () => '-',
    },
    {
      label: translate('COMMON.LABELS.PROFIT_LOSS_BALANCE'),
      getValue: item => formatDecimal(item.deal?.pl),
    },
    {
      label: translate('COMMON.LABELS.COMMENT'),
      getValue: item => item.deal?.comment || '-',
    },
  ];

  const getColWidth = (index: number) => {
    // eslint-disable-next-line no-magic-numbers
    const colWidth = `${100 / mainRowTableColumns.length}%`;
    const dateWidth = '80px';

    return index === 1 || index === 2 ? dateWidth : colWidth;
  };

  if (!accountStatement.openPositions.length) {
    return (
      <>
        <Text style={{ ...styles.h2, marginBottom: '10px' }}>
          {translate('COMMON.LABELS.DEALS')}
        </Text>
        <Text style={styles.span}>
          {translate('COMMON.LABELS.NO_TRANSACTIONS')}
        </Text>
      </>
    );
  }

  return (
    <>
      <Text style={{ ...styles.h2, marginBottom: '10px' }}>
        {translate('COMMON.LABELS.DEALS')}
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {mainRowTableColumns.map((data, index) => (
            <View key={data.label} style={{ width: getColWidth(index) }}>
              <Text style={styles.tableCellHeader}>{data.label}</Text>
            </View>
          ))}
        </View>
        {tableData.map((item, index) => (
          <View
            key={item.isSubRow ? item.deal?.dealId : item.positionId}
            style={styles.tableRow}
          >
            {(item.isSubRow ? subRowTableColumns : mainRowTableColumns).map(
              (data, dataIndex) => (
                <View
                  key={data.label}
                  style={{
                    width: getColWidth(dataIndex),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor:
                      !item.isSubRow && tableData[index + 1]?.isSubRow
                        ? '#F1F1F1'
                        : undefined,
                  }}
                >
                  {item.isSubRow && dataIndex === 0 && (
                    <Svg
                      width='5'
                      height='5'
                      viewBox='0 0 5 5'
                      style={{ margin: '0 4px' }}
                    >
                      <Path
                        d='M3.0625 2.125L4 3.0625L3.0625 4'
                        stroke='#14181F'
                        strokeWidth='0.5'
                        strokeLinejoin='round'
                      />
                      <Path
                        d='M1 1V2.3125C1 2.51141 1.07902 2.70218 1.21967 2.84283C1.36032 2.98348 1.55109 3.0625 1.75 3.0625H4'
                        stroke='#14181F'
                        strokeWidth='0.5'
                        strokeLinejoin='round'
                      />
                    </Svg>
                  )}
                  <Text
                    style={{
                      ...styles.tableCell,
                      fontWeight:
                        !item.isSubRow && tableData[index + 1]?.isSubRow
                          ? // eslint-disable-next-line no-magic-numbers
                            700
                          : undefined,
                    }}
                  >
                    {data.getValue(item)}
                  </Text>
                </View>
              )
            )}
          </View>
        ))}
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Results = ({
  accountStatement,
  currency,
}: {
  accountStatement: IAccountStatementBE;
  currency: string;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>{translate('COMMON.LABELS.RESULTS')}</Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.row}>
          <View style={styles.col4}>
            <View style={styles.column}>
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.TOTAL_NET_PROFIT')}
                value={formatDecimal(accountStatement.netProfit)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.PROFIT_FACTOR')}
                value={formatDecimal(accountStatement.profitFactor)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.RECOVERY_FACTOR')}
                value={translate('COMMON.LABELS.N_A')}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN')}
                value={translate('COMMON.LABELS.N_A')}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN_ABSOLUTE')}
                value={formatDecimal(accountStatement.absoluteDrawdown)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.TOTAL_TRADES')}
                value={accountStatement.totalTrades}
              />
            </View>
          </View>
          <View style={styles.col4}>
            <View style={styles.column}>
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.GROSS_PROFIT')}
                value={formatDecimal(accountStatement.grossProfit)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.EXPECTED_PAYOFF')}
                value={formatDecimal(accountStatement.expectedPayoff)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.SHARPE_RATIO')}
                value={translate('COMMON.LABELS.N_A')}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN_MAXIMAL')}
                value={`${formatDecimal(
                  accountStatement.maxDrawdown.value
                )} (${formatDecimal(accountStatement.maxDrawdown.percent)}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.SHORT_TRADES_WON')}
                value={`${accountStatement.shortTrades.value} (${formatDecimal(
                  accountStatement.shortTrades.percent
                )}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.PROFIT_TRADES_OF_TOTAL')}
                value={`${accountStatement.profitTrades.value} (${formatDecimal(
                  accountStatement.profitTrades.percent
                )}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LARGEST_PROFIT_TRADE')}
                value={accountStatement.largestTrade}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_PROFIT_TRADE')}
                value={accountStatement.avgProfitTrade}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_WINS', {
                  currency: currency,
                })}
                value={`${
                  accountStatement.maxConsecutiveProfit
                } (${formatDecimal(accountStatement.maxConsecutiveWins)})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.MAXIMAL_CONSECUTIVE_WIN_COUNT')}
                value={`${accountStatement.maxConsecutiveWins} (${formatDecimal(
                  accountStatement.maxConsecutiveProfit
                )})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_WINS')}
                value={accountStatement.avgConsecutiveWins}
              />
            </View>
          </View>
          <View style={styles.col4}>
            <View style={styles.column}>
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.GROSS_LOSS')}
                value={formatDecimal(accountStatement.grossLoss)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN_RELATIVE')}
                value={`${formatDecimal(
                  accountStatement.relativeDrawdown.value
                )} (${formatDecimal(
                  accountStatement.relativeDrawdown.percent
                )}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LONG_TRADES_WON')}
                value={`${formatDecimal(
                  accountStatement.longTrades.value
                )} (${formatDecimal(accountStatement.longTrades.percent)}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LOSS_TRADES_OF_TOTAL')}
                value={`${formatDecimal(
                  accountStatement.lossTrades.value
                )} (${formatDecimal(accountStatement.lossTrades.percent)}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LARGEST_LOSS_TRADE')}
                value={formatDecimal(accountStatement.largestLossTrade)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_LOSS_TRADE')}
                value={formatDecimal(accountStatement.avgLossTrade)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES', {
                  currency: currency,
                })}
                value={`${formatDecimal(
                  accountStatement.maxConsecutiveLoss
                )} (${formatDecimal(accountStatement.maxConsecutiveLosses)})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate(
                  'COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES_COUNT'
                )}
                value={`${formatDecimal(
                  accountStatement.maxConsecutiveLosses
                )} (${formatDecimal(accountStatement.maxConsecutiveLoss)})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_LOSSES')}
                value={accountStatement.avgConsecutiveLosses}
              />
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
