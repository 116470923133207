import styled, { css } from 'styled-components';

export const Header = styled('tr')<{ $withTransparentHeader: boolean }>`
  ${({ theme, $withTransparentHeader }) => css`
    background: ${theme.palette.common.white};

    th {
      background-color: ${$withTransparentHeader
        ? undefined
        : theme.palette.primary['100']};
      font-weight: normal;
      color: ${theme.palette.gray['400']};
      font-size: ${theme.typography.default.fontSize};
      border: none;
      border-bottom: 1px solid
        ${theme.palette.mode === 'dark'
          ? 'rgb(81, 81, 81)'
          : theme.palette.gray.light};
      padding: 6px 0;
    }
  `};
`;

export const Cell = styled('td')`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.typography.small.fontSize};
    border: none;
    border-bottom: 1px solid
      ${theme.palette.mode === 'dark'
        ? 'rgb(81, 81, 81)'
        : theme.palette.gray.light};
    background-color: ${theme.palette.common.white};
    padding: 4px 0;
  `};
`;
