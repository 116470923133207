import { TInstrumentEntity } from '@trader/store';

export const getTCInfoUByKey = (indicator: string) => {
  switch (indicator) {
    case 'pivot':
      return {
        color: 'rgb(101, 67, 186)',
        title: 'Pivot',
      };
    case 'resistance1':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R1',
      };
    case 'resistance2':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R2',
      };
    case 'resistance3':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R3',
      };
    case 'support1':
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S1',
      };
    case 'support2':
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S2',
      };
    case 'support3':
    default:
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S3',
      };
  }
};

export const getTradingCentralValues = (
  tradingCentral: TInstrumentEntity['tradingCentral']
) => {
  if (!tradingCentral) {
    return [];
  }

  const indicator = tradingCentral?.feeds[tradingCentral?.feeds?.length - 1];

  if (!indicator) {
    return [];
  }

  const title = indicator.title;
  const pivot = indicator.paragraphs[0];
  const preference = indicator.paragraphs[1];
  const scenario = indicator.paragraphs[2];
  const comment = indicator.paragraphs[3];
  const supportAndResistant = indicator.paragraphs[4];
  const resistantValues = indicator.paragraphs
    // eslint-disable-next-line no-magic-numbers
    .slice(4)
    .filter(item => /^[\d.]+\s?(\*\*|\*|last)?$/i.test(item));
  const date = new Date(indicator.time).toUTCString();

  return [
    title,
    pivot,
    preference,
    scenario,
    comment,
    supportAndResistant,
    ...resistantValues,
    date,
  ];
};
