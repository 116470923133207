import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Progress, Tabs, Typography } from '@trader/components';
import { useI18next } from '@trader/services';
import { useLayouts } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';

import { Chart } from './components/chart';

import * as Styled from './styled';
import { InstrumentDetailsHeader } from '@trader/containers';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';

const KeyStatisticsLazy = React.lazy(() =>
  import('@trader/containers').then(({ KeyStatistics }) => ({
    default: KeyStatistics,
  }))
);
const RelatedInstrumentsLazy = React.lazy(() =>
  import('@trader/containers').then(({ RelatedInstruments }) => ({
    default: RelatedInstruments,
  }))
);
const InstrumentPositionsLazy = React.lazy(() =>
  import('@trader/containers').then(({ InstrumentPositions }) => ({
    default: InstrumentPositions,
  }))
);
const InstrumentOrdersLazy = React.lazy(() =>
  import('@trader/containers').then(({ InstrumentOrders }) => ({
    default: InstrumentOrders,
  }))
);

export const SingleLayout: React.FC = observer(() => {
  const { translate } = useI18next();

  const store = useMst();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);
  const isLoading =
    store.entities.instruments.getInstrumentSpecificationAsync.inProgress ||
    store.pages.trading.getPrefetchInformationAsync.inProgress;

  useLayouts(symbol);

  useEffect(() => {
    symbol &&
      store.entities.instruments.getInstrumentSpecificationAsync.run(symbol);
  }, [symbol]);

  const tabs = () => {
    const items = [
      {
        label: translate('COMMON.LABELS.CHART'),
        value: 'chart',
        children: <Chart />,
      },
      {
        label: translate('COMMON.LABELS.KEY_STATISTICS'),
        value: 'key-statistics',
        children: (
          <React.Suspense fallback={<Progress />}>
            <KeyStatisticsLazy />
          </React.Suspense>
        ),
      },
      {
        label: translate('COMMON.LABELS.RELATED_INSTRUMENTS'),
        value: 'related-instruments',
        children: (
          <React.Suspense fallback={<Progress />}>
            <RelatedInstrumentsLazy />
          </React.Suspense>
        ),
      },
      {
        label: translate('COMMON.LABELS.OPEN_POSITIONS'),
        value: 'open-positions',
        children: (
          <React.Suspense fallback={<Progress />}>
            <InstrumentPositionsLazy />
          </React.Suspense>
        ),
      },
    ];

    if (!isInvestmentAccProduct) {
      items.push({
        label: translate('COMMON.LABELS.ORDERS'),
        value: 'orders',
        children: (
          <React.Suspense fallback={<Progress />}>
            <InstrumentOrdersLazy />
          </React.Suspense>
        ),
      });
    }

    return items;
  };

  return (
    <Styled.Root>
      {!instrument && !isLoading ? (
        <Typography padding='8px' variant='large' textAlign='center'>
          {translate('COMMON.LABELS.NO_DATA_TO_SHOW_CHART')}
        </Typography>
      ) : (
        <InstrumentDetailsHeader
          layout={EChartLayoutTypes.Single}
          layoutNumber={EChartLayouts.FirstLayout}
        />
      )}
      {store.app.isBetaDesignEnabled() ? (
        <Chart />
      ) : (
        <Tabs navigationKey='layoutView' tabs={tabs()} hasNavigationBar />
      )}
    </Styled.Root>
  );
});
