import styled, { css } from 'styled-components';

import { Typography } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { layoutMargin } from '@trader/constants';

export const Paragraph = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.small.fontSize};
    line-height: 20px;
    white-space: pre-wrap;
    text-overflow: clip;
    margin-top: 6px;

    > span {
      font-weight: 600;
    }

    .resistance {
      color: rgb(49, 146, 26);
    }

    .support {
      color: rgb(219, 46, 39);
    }

    .pivot {
      color: rgb(101, 67, 186);
    }
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-weight: 600;
    margin-top: 4px;
    white-space: pre-wrap;
    text-overflow: clip;
  `};
`;

const zoomedImageHeight = 420;
export const TradingCentralChartImage = styled('img')<{ $isZoomed: boolean }>`
  ${({ $isZoomed }) => css`
    cursor: zoom-in;

    ${conditionApply(
      [$isZoomed],
      css`
        cursor: zoom-out;
        position: fixed;
        right: ${layoutMargin}px;
        width: 500px;
        height: ${zoomedImageHeight}px;
      `
    )};
  `};
`;

const zoomedImageOffset = 10;
export const Information = styled('div')<{ $isZoomed: boolean }>`
  ${({ $isZoomed }) => css`
    margin-top: ${$isZoomed
      ? `${zoomedImageHeight + zoomedImageOffset}px`
      : undefined};
  `};
`;
