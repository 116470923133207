import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { getTradingCentralValues, isValidDate } from '@trader/utils';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const TradingCentral: React.FC = observer(() => {
  const [isChartImageZoomed, setIsChartImageZoomed] = useState(false);

  const store = useMst();
  const { translate } = useI18next();

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  const tradingCentral =
    store.entities.instruments.get<TInstrumentEntity>(symbol)?.tradingCentral;
  const tradingCentralValues = useMemo(() => {
    return getTradingCentralValues(tradingCentral);
  }, [symbol, tradingCentral]);

  const handleChartImageClick = () => {
    setIsChartImageZoomed(prev => !prev);
  };

  const renderParagraph = (value: string) => {
    const hasTitle = value.split(':').length > 1;
    const isDate = isValidDate(value);

    if (isDate) {
      return <b>{value}</b>;
    }

    if (hasTitle) {
      return (
        <>
          <b>{value.split(':')[0]}:</b>
          {value.split(':')[1]}
        </>
      );
    }

    return value;
  };

  if (!tradingCentral) {
    return null;
  }

  if (!tradingCentral.feeds.length) {
    return (
      <Styled.Paragraph>
        {translate('COMMON.LABELS.INSTRUMENT_NOT_COVERED_TRADING_CENTRAL')}
      </Styled.Paragraph>
    );
  }

  return (
    <>
      <Styled.TradingCentralChartImage
        $isZoomed={isChartImageZoomed}
        src={tradingCentral.feeds[0].imageUrl}
        alt='trading-central-chart'
        onClick={handleChartImageClick}
      />
      <Styled.Information $isZoomed={isChartImageZoomed}>
        {tradingCentralValues.map(v => (
          <Styled.Paragraph key={v}>{renderParagraph(v)}</Styled.Paragraph>
        ))}
        <Styled.Title style={{ marginTop: '20px' }}>
          {translate('COMMON.LABELS.LEGEND')}
        </Styled.Title>
        <Styled.Paragraph>
          <span className='resistance'>R1, R2, R3</span>{' '}
          {translate('CHART.LINES_REPRESENT_RESISTANCE')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          <span className='support'>S1, S2, S3</span>{' '}
          {translate('CHART.LINES_REPRESENT_SUPPORT_LEVELS')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          <span className='pivot'>{translate('COMMON.LABELS.PIVOT')}</span>{' '}
          {translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT')}
        </Styled.Paragraph>
      </Styled.Information>
    </>
  );
});
